import React, { useState, useEffect } from 'react';

//? styles
import * as Styles from './styles';

//? components
import BtnSliders from 'components/BtnSliders';
import TitleSection from 'components/TitleSection';

//? data
import items from '../../../data/pages/03-ThirdComponent';

//? images

import thunder from '../../../images/thunder.png';
import line from '../../../images/line.png';

function ThirdComponent() {
  const [slideIndex, setSlideIndex] = useState(1);
  const [rotateNumber] = useState(45);

  const prevSlide = () => {
    if (slideIndex !== 1) {
      setSlideIndex(slideIndex - 1);
    } else if (slideIndex === 1) {
      setSlideIndex(items.length);
    }
  };
  const nextSlide = () => {
    if (slideIndex !== items.length) {
      setSlideIndex(slideIndex + 1);
    } else if (slideIndex === items.length) {
      setSlideIndex(1);
    }
  };

  // useEffect(() => {
  //   let slider = setInterval(() => {
  //     if (slideIndex !== items.length) {
  //       setSlideIndex(slideIndex + 1);
  //     } else if (slideIndex === items.length) {
  //       setSlideIndex(1);
  //     }
  //   }, 4000);
  //   return () => {
  //     clearInterval(slider);
  //   };
  // }, [slideIndex]);

  return (
    <>
      <Styles.Container id="boxscroll3">
        <TitleSection
          Title="SERVIÇOS"
          SubTitleBold="O que"
          SubTitle="fazemos?"
        />

        <div className="slider-container">
          <div className="slider-content">
            {items.map((item, index) => {
              const { id, title, titleMob, subTitle, paragraph } = item;
              return (
                <div
                  key={id}
                  className={
                    slideIndex === index + 1
                      ? 'banners active-banner'
                      : 'banners'
                  }
                >
                  <div className="img-content">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        `/images/how/how-images-${index + 1}.png`
                      }
                      alt=""
                    />
                  </div>

                  <div className="info-content">
                    <h2 className="title">
                      <span className="title-line">
                        <img src={line} alt="" />
                      </span>
                      <div className="title-content">
                        <img src={thunder} className="icon-thunder" alt="" />
                        <span
                          dangerouslySetInnerHTML={{ __html: title }}
                          className="title-desktop"
                        />
                        <span
                          dangerouslySetInnerHTML={{ __html: titleMob }}
                          className="title-mob"
                        />
                      </div>
                    </h2>
                    <h3 className="subtitle">
                      <span dangerouslySetInnerHTML={{ __html: subTitle }} />
                    </h3>
                    <p className="paragraph">
                      <span dangerouslySetInnerHTML={{ __html: paragraph }} />
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <Styles.ElipseContainer
            rotate={`rotate(${rotateNumber * slideIndex}deg)`}
            rotateMob={`rotate(${135 + rotateNumber * slideIndex}deg)`}
          >
            <div className="pulse"></div>
            <div className="bullets-container">
              {Array.from({ length: 8 }).map((item, index) => (
                <span
                  key={index}
                  className={slideIndex === index + 1 ? 'active-bullet' : ''}
                ></span>
              ))}
            </div>
          </Styles.ElipseContainer>
        </div>
        <div className="nav-container">
          <BtnSliders moveSlide={prevSlide} direction={'prev'} />
          <BtnSliders moveSlide={nextSlide} direction={'next'} />
        </div>
        <div className="counter-360"></div>
        <div className="flag"></div>
      </Styles.Container>
    </>
  );
}

export default ThirdComponent;
