import React, { useEffect, useRef } from 'react';

//? styles
import * as Styles from './styles';

import VideoBg from '../../../videos/home.mp4';

function FirstComponent() {
  const vidRef = useRef();

  useEffect(() => {
    window.setTimeout(() => 
      vidRef.current.play(), 100
    );

  }, []);
  return (
    <>
      <Styles.Container id="boxscroll1">
        <video
          src={VideoBg}
          ref={vidRef}
          muted
          autoPlay
          loop
          className="video-bg"
        />

        <div className="container-box">
          <h1 className="title">
            O NOSSO
            <br />
            NEGÓCIO É <br />
            <div className="works">
              <b>HUMANO</b>
              <b>TECNOLÓGICO</b>
              <b>CRIATIVO</b>
              <b>DIGITAL</b>
              <b>INOVADOR</b>
              <b>HUMANO</b>
            </div>
          </h1>

          <div className="button-scroll">
            <span></span>
            Scroll Down
          </div>
        </div>
      </Styles.Container>
    </>
  );
}

export default FirstComponent;
