import styled from 'styled-components';

import colors from 'styles/colors';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  position: relative;
  background: ${colors.gray};
`;

export const Content = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: calc(min(1000px, 100%) - 40px);
  position: relative;

  .text-content {
    position: relative;
    margin: 0;
    .title {
      font-size: 55px;
      font-weight: 800;
      font-style: italic;
      color: ${colors.white};
    }
    .icon {
      position: absolute;
      top: 30px;
      left: -40px;
      img {
        transform: scale(0.6) rotate(-5deg);
      }
    }
  }

  .subtitle {
    font-size: 45px;
    line-height: 45px;
    font-weight: 800;
    font-style: italic;
    color: ${colors.green};
    margin: 40px 0;
  }
  .paragraph {
    font-size: 25px;
    line-height: 35px;
    font-weight: 100;
    font-style: italic;
    color: ${colors.white};

    margin-bottom: 80px;
    span {
      font-weight: 800;
    }
  }

  .contact-content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: calc(min(900px, 100%) - 40px);
    position: relative;

    .contact-img {
      display: flex;
      align-items: flex-start;
      img {
        position: absolute;
        left: -102px;
        top: -90px;
        transform: scale(0.7);
      }
    }

    .contact-info {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      margin-top: 50px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        width: 600px;
        border: 1px solid ${colors.green};
      }
      .contact-link {
        font-size: 30px;
        line-height: 80px;
        font-style: italic;
        text-decoration: none;
        color: ${colors.green};
        font-weight: 100;
        transition: 0.3s;
        &:hover {
          color: ${colors.white};
        }
      }
    }
  }

  @media (max-width: 1450px) {
    .text-content {
      position: relative;
      margin: 0;
      .title {
        font-size: 45px;
        font-weight: 800;
        font-style: italic;
        color: ${colors.white};
      }
      .icon {
        position: absolute;
        top: 23px;
        left: -45px;
        img {
          transform: scale(0.6) rotate(-5deg);
        }
      }

      .subtitle {
        font-size: 45px;
        line-height: 45px;
        font-weight: 800;
        font-style: italic;
        color: ${colors.green};
        margin: 50px 0 20px;
      }
      .paragraph {
        font-size: 25px;
        line-height: 35px;
        font-weight: 100;
        font-style: italic;
        color: ${colors.white};

        margin-bottom: 30px;
        span {
          font-weight: 800;
        }
      }
    }

    .contact-content {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: calc(min(900px, 100%) - 40px);
      position: relative;

      .contact-img {
        display: flex;
        align-items: flex-start;
        img {
          position: absolute;
          left: -102px;
          top: -90px;
          transform: scale(0.7);
        }
      }

      .contact-info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        margin-top: 50px;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          right: 0;
          width: 600px;
          border: 1px solid ${colors.green};
        }
        .contact-link {
          font-size: 30px;
          line-height: 80px;
          font-style: italic;
          text-decoration: none;
          color: ${colors.green};
          font-weight: 100;
          transition: 0.3s;
          &:hover {
            color: ${colors.white};
          }
        }
      }
    }

    /* ....................................... */
  }

  @media (max-width: 425px) {
    .text-content {
      position: relative;
      margin: -80px 0 0px;
      .title {
        font-size: 40px;
        line-height: 25px;
      }

      .icon {
        position: absolute;
        top: 5px;
        left: -50px;
        img {
          transform: scale(0.55) rotate(-8deg);
        }
      }

      .subtitle {
        font-size: 25px;
        line-height: 35px;
        font-weight: 800;
        font-style: italic;
        color: ${colors.green};
        margin: 70px 0 20px;
      }
      .paragraph {
        font-size: 20px;
        line-height: 35px;
        font-weight: 100;
        font-style: italic;
        color: ${colors.white};

        span {
          font-weight: 800;
        }
      }
    }

    .contact-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column-reverse;
      width: 100%;

      .contact-img {
        display: flex;
        align-items: flex-start;
        img {
          position: absolute;
          left: 50%;
          top: unset;
          bottom: -280px;
          transform: translateX(-50%) scale(0.4);
        }
      }

      .contact-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 0;
        position: relative;
        &:before {
          width: 150px;
          right: unset;
          left: 50%;
          transform: translate(-50%, 0);
        }
        .contact-link {
          font-size: 25px;
          line-height: 60px;
          font-style: italic;

          &:hover {
            color: ${colors.green};
          }
        }
      }
    }
  }
`;
