import React from 'react';
import { Container } from './styles';

// images

import { FaInstagram, FaLinkedinIn } from 'react-icons/fa';

function SocialMedia({ instagramTitle, linkedinTitle }) {
  return (
    <>
      <Container>
        <ul className="list">
          <li>
            <a
              href="https://www.instagram.com/sabion_digital/"
              target="_blank"
              alt="Visite nosso Instagram"
              className="list-item"
              id={instagramTitle}
            >
              <FaInstagram size={22} />
            </a>

            <a
              href="https://www.linkedin.com/company/sabion-digital"
              className="list-item"
              alt="Acesse o nosso LinkedIn"
              target="_blank"
              id={linkedinTitle}
            >
              <FaLinkedinIn size={22} />
            </a>
          </li>
        </ul>
      </Container>
    </>
  );
}

export default SocialMedia;
