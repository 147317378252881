import React from 'react';
import { motion } from 'framer-motion';

//? styles
import * as Styles from './styles';

import { cardVariants } from '../../../helpers/animation';

//? images
import label01 from '../../../images/label/label-01.png';
import label02 from '../../../images/label/label-02.png';
import label03 from '../../../images/label/label-03.png';
import thunder from '../../../images/thunder.png';

function FifthComponent() {
  return (
    <Styles.Container
      exit={{ opacity: 0 }}
      initial="initial"
      animate="animate"
      id="boxscroll5"
    >
      <Styles.Content>
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.8 }}
          className="text-container"
        >
          <motion.h3 variants={cardVariants} className="title">
            PRÊMIOS
          </motion.h3>
          <motion.h2 variants={cardVariants} className="subtitle">
            Reconhecimento pelo que <br /> fazemos :)
          </motion.h2>
        </motion.div>
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.8 }}
          className="img-container"
        >
          <motion.img
            src={label01}
            variants={cardVariants}
            className="label-img"
            alt=""
          />
          <motion.img
            src={label02}
            variants={cardVariants}
            className="label-img"
            alt=""
          />
          <motion.img
            src={label03}
            variants={cardVariants}
            className="label-img"
            alt=""
          />
        </motion.div>
      </Styles.Content>
      <Styles.ElipseContainer>
        <div className="elipse"></div>
        <div className="icon">
          <img src={thunder} width={55} alt="" />
        </div>
      </Styles.ElipseContainer>
    </Styles.Container>
  );
}

export default FifthComponent;
