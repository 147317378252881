import styled from 'styled-components';

import colors from 'styles/colors';

export const Container = styled.div`
  a {
    transition: 0.3s;
    text-decoration: none;
    color: ${(props) => (props.color ? colors.gray : colors.white)};
    &:hover {
      color: ${colors.green};
    }
    &.list-item {
      margin: ${(props) => props.addMargin ?? '0 10px'};
    }
  }

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 425px) {
    a {
      &:hover {
        color: ${colors.white};
      }
    }
  }
`;
