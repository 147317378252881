import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div`
  position: absolute;
  left: 5%;
  top: 13%;
  width: 300px;
  .title {
    font-size: 16px;
    line-height: 50px;
    font-weight: 700;
    color: ${(props) => (props.Color ? colors.white : colors.gray)};
  }

  .subtitle {
    font-size: 45px;
    line-height: 48px;
    font-weight: 200;
    color: ${(props) => (props.Color ? colors.white : colors.gray)};
    span {
      font-weight: 700;
    }
  }
  &:after {
    content: '';
    margin-top: 25px;
    width: 85px;
    height: 16px;
    background: ${(props) => (props.BorderColor ? colors.gray : colors.green)};
    display: flex;
    border-radius: 10px;
  }

  @media (max-width: 1450px) {
  }

  @media (max-width: 425px) {
    .title {
      font-size: 16px;
      line-height: 30px;
      font-weight: 700;
      color: ${(props) => (props.Color ? colors.white : colors.gray)};
    }

    .subtitle {
      font-size: 30px;
      line-height: 35px;
      font-weight: 200;
      color: ${(props) => (props.Color ? colors.white : colors.gray)};
      span {
        font-weight: 700;
      }
    }
    &:after {
      content: '';
      margin-top: 10px;
      width: 60px;
      height: 15px;
      background: ${(props) =>
        props.BorderColor ? colors.gray : colors.green};
      display: flex;
      border-radius: 10px;
    }
  }
`;
