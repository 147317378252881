import styled from 'styled-components';
import { rules } from 'styles/breakpoints';
import colors from 'styles/colors';

export const HeaderContainer = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.15);

  .header {
    z-index: 3;
    width: ${rules.xl};
    padding: 20px 0 10px;
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 425px) {
    .header {
      .__list-item {
        &:hover {
          color: ${colors.white};
        }
        &.list-item {
          margin: 0 10px;
        }
      }
    }
  }
`;

export const BrandContent = styled.div`
  ul {
    list-style: none;
    padding: 0;
    li {
    }
  }
`;
export const IconsContent = styled.div``;

export const Container = styled.div`
  ul {
    position: fixed;
    bottom: 30px;
    right: 30px;
    li {
      list-style: none;
      a {
        line-height: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: ${colors.green};
        color: ${colors.white};
        transition: 0.3s;
        cursor: pointer;
        z-index: 99999;
        &:hover {
          color: ${colors.gray};
        }
      }
    }
  }

  @media (max-width: 425px) {
    ul {
      li {
        a {
          cursor: none;

          &:hover {
            color: ${colors.white};
          }
        }
      }
    }
  }
`;

export const FixedBar = styled.div`
  background: rgba(46, 46, 46, 0.9);
  bottom: 0;
  color: #fff;
  position: fixed;
  padding: 25px 50px 15px;
  width: 100%;
  z-index: 10;

  div {
    margin: 0 auto;
    position: relative;
    width: ${rules.xl};

    @media (max-width: 425px) {
      width: 100%;
    }
  }

  .cookie {
    position: absolute;
    left: -80px;
    top: -50px;

    img {
      position: relative;
      z-index: 10;
    }

    @media (max-width: 425px) {
      left: -50px;
    }
  }

  .cookie::before {
    content: '';
    position: absolute;
    left: 35px;
    top: 0;
    width: 50px;
    height: 50px;
    border: 2px dashed #12FC9E;
    border-radius: 30px;
  }
  
  .cookie::after {
    content: '';
    position: absolute;
    left: 50px;
    top: 25px;
    width: 40px;
    height: 25px;
    background: #2b2b2b;
  }

  .fixed-dismiss {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
  }

  h3 {
    text-transform: uppercase;
    font-size: 1.25rem;
  }

  a {
    color: #fff;
    font-weight: 600;
    text-decoration: underline;
  }
`