import React from 'react';

//? styles
import * as Styles from './styles';

// images

import BussinesIntelligenceImg from '../../../images/bussines-intelligence-v3.png';

function SecondComponent() {
  return (
    <>
      <Styles.Container id="boxscroll2">
        <div className="row col-12">
          <div className="icon">
            <img src={BussinesIntelligenceImg} alt="" />
          </div>

          <div className="marquee">
            {Array.from({ length: 3 }).map((item, index) => (
              <div key={index} className="text-container">
                <div className="text">
                  <h3 className="title">NOSSA MISSÃO É TORNAR </h3>
                  <h3 className="title green">DIGITAL MAIS HUMANO.</h3>
                  <h3 className="title">NOSSA MISSÃO É TORNAR </h3>
                  <h3 className="title green">DIGITAL MAIS HUMANO.</h3>
                  <h3 className="title">NOSSA MISSÃO É TORNAR </h3>
                  <h3 className="title green">DIGITAL MAIS HUMANO.</h3>
                </div>
                <div className="text">
                  <h3 className="title">NOSSA MISSÃO É TORNAR </h3>
                  <h3 className="title green">DIGITAL MAIS HUMANO.</h3>
                  <h3 className="title">NOSSA MISSÃO É TORNAR </h3>
                  <h3 className="title green">DIGITAL MAIS HUMANO.</h3>
                  <h3 className="title">NOSSA MISSÃO É TORNAR </h3>
                  <h3 className="title green">DIGITAL MAIS HUMANO.</h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Styles.Container>
    </>
  );
}

export default SecondComponent;
