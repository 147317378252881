const easing = [0.6, -5, 0.01, 0.99];

export const stagger = {
  animate: {
    transition: {
      staggerChildren: 0.05,
    },
  },
};

export const cardVariants = {
  offscreen: {
    opacity: 0,
    y: 300,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      bounce: 0.4,
      duration: 1.5,
      ease: easing,
    },
  },
};
export const cardVariantsLeft = {
  offscreen: {
    opacity: 0,
    x: 300,
  },
  onscreen: {
    x: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      bounce: 0.4,
      duration: 1.5,
      ease: easing,
    },
  },
};
export const cardVariantsRight = {
  offscreen: {
    opacity: 0,
    x: -300,
  },
  onscreen: {
    x: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      bounce: 0.4,
      duration: 1.5,
      ease: easing,
    },
  },
};
