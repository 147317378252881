import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.section`
  height: 100vh;
  width: 100%;
  background: ${colors.gray};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: 0.3s;
  .icon {
    width: 100%;

    text-align: center;
    position: absolute;
    transition: 0.3s;
    top: 50%;
    left: 50%;
    z-index: 99;

    transform: translate(-50%, -50%);
    img {
      width: min(450px, 100%);
      height: auto;
    }
  }

  .marquee {
    opacity: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    transition: all 0.3s;
    box-sizing: inherit;

    font-weight: 600;

    .text-container {
      display: flex;

      .text {
        padding: 5px 0;
        user-select: none;
        display: inline-flex;
        flex-direction: row;
        white-space: nowrap;

        .title {
          font-size: 70px;
          line-height: 86px;
          margin: 0 20px 0 0;
          font-weight: 600;
          color: ${colors.white};
          &.green {
            color: ${colors.green};
          }
        }
      }

      &:nth-child(odd) {
        .text {
          animation: marquee 70s linear both infinite;
        }
      }
      &:nth-child(even) {
        .text {
          animation: marquee 70s linear reverse infinite;
        }
      }

      &:nth-child(1) {
        .text {
          animation: marquee 90s linear both infinite;
        }
      }
      &:nth-child(2) {
        .text {
          animation: marquee 90s linear both reverse infinite;
        }
      }
      &:nth-child(3) {
        .text {
          animation: marquee 120s linear both infinite;
        }
      }
    }
  }

  @media (max-width: 1450px) {
    .icon {
    }
  }
  @media (max-width: 425px) {
    .marquee {
      .text-container {
        .text {
          .title {
            font-size: 60px;
            line-height: 70px;
          }
        }
      }
    }
  }
  @keyframes marquee {
    0% {
      transform: translateZ(0);
    }
    100% {
      transform: translate3d(-100%, 0, 0);
    }
  }
`;
