import styled from 'styled-components';
import { rules } from 'styles/breakpoints';
import colors from 'styles/colors';

export const Container = styled.section`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  overflow: hidden;

  .slider-container {
    width: ${rules.xl};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 600px;
    overflow: hidden;
    transition: all 0.4s;
    z-index: 99;
    position: relative;
    .slider-content {
      width: 100%;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      .banners {
        position: relative;
        width: ${rules.xl};
        height: 350px;
        display: none;
        align-items: flex-start;
        justify-content: flex-end;
        opacity: 0;
        transition: ease 2s;
        .img-content {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 99;
        }
        .info-content {
          width: 340px;
          .title {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .title-line {
              position: absolute;
              left: -110px;
              top: 0px;
            }

            .title-content {
              .title-desktop {
                font-size: 36px;
                line-height: 36px;
                font-weight: 600;
              }
              .title-mob {
                display: none;
              }

              .icon-thunder {
                width: 25px;
                margin: -5px 5px 0 0;
              }
            }
          }
          .subtitle {
            font-size: 20px;
            line-height: 25px;
            font-weight: 500;
            margin: 20px 0;
          }

          .paragraph {
            font-size: 17px;
            line-height: 30px;
            font-weight: 300;
          }
        }

        &.active-banner {
          opacity: 1;
          display: flex;
        }
      }
    }
  }

  .nav-container {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 15px);
    bottom: 20%;
    width: 360px;

    bottom: 20%;
    z-index: 9999;

    &:before {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, 25px);
      content: '';
      width: 100px;
      border: 1px solid ${colors.green};
    }
  }

  .counter-360 {
    animation: counter 7s infinite cubic-bezier(0, 0, 0, 0.99);
    counter-reset: num var(--num);
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-color: ${colors.green};
    -webkit-text-stroke-width: 1px;
    font-size: 140px;
    font-weight: 600;
    position: absolute;
    bottom: -60px;
    left: 20px;
    font-family: 'Raleway', sans-serif;
    &:after {
      content: counter(num);
    }
  }

  .flag {
    position: absolute;
    top: -20px;
    width: 40px;
    height: 40px;
    background: ${colors.gray};
    transform: rotate(45deg);
  }

  @media (max-width: 1450px) {
    .nav-container {
      bottom: 12%;
      z-index: 9999;
    }
  }

  @media (max-width: 425px) {
    .slider-container {
      .slider-content {
        .banners {
          height: 600px;
          display: none;
          align-items: flex-end;
          justify-content: flex-end;

          .img-content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -89%);
            img {
              width: 150px;
              height: auto;
            }
          }
          .info-content {
            width: 100%;

            .title {
              .title-line {
                display: none;
              }

              .title-content {
                .title-desktop {
                  display: none;
                }
                .title-mob {
                  font-size: 25px;
                  display: inline-block;
                  line-height: 30px;
                  font-weight: 600;
                  position: relative;
                  span {
                    br {
                      display: none;
                    }
                  }
                }
              }
            }
            .subtitle {
              font-size: 14px;
              line-height: 20px;
              font-weight: 500;
              margin: 15px 0;
            }

            .paragraph {
              font-size: 14px;
              line-height: 20px;
              font-weight: 400;
            }
          }

          &.active-banner {
            opacity: 1;
            display: flex;
          }
        }
      }
    }

    .nav-container {
      position: absolute;
      left: 50%;
      bottom: 10%;

      width: 0px;

      z-index: 9999;

      &:before {
        display: none;
      }
    }

    .counter-360 {
      font-size: 80px;
      font-weight: 600;
      position: absolute;
      bottom: -40px;
      left: -0px;
      opacity: 0.5;

      &:after {
        content: counter(num);
      }
    }

    .flag {
      position: absolute;
      top: -20px;
      width: 40px;
      height: 40px;
      background: ${colors.gray};
      transform: rotate(45deg);
    }
  }

  @keyframes pulsed {
    0% {
      transform: scale(0.2);
      opacity: 0;
    }

    50% {
      opacity: 1;
    }
    100% {
      transform: scale(0.93);
      opacity: 0;
    }
  }

  @property --num {
    syntax: '<integer>';
    initial-value: 0;
    inherits: false;
  }

  @keyframes counter {
    from {
      --num: 0;
    }
    to {
      --num: 360;
    }
  }
`;

export const ElipseContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 420px;
  height: 420px;
  border-radius: 50%;
  border: 2px solid ${colors.green};
  top: 50%;
  left: 50%;
  transition: 0.8s ease-in-out;
  transform: translate(-50%, -50%) ${(props) => props.rotate ?? ''};
  .pulse {
    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      border-radius: 50%;
      border: 1px solid ${colors.green};
      top: -20px;
      bottom: -20px;
      left: -20px;
      right: -20px;
      transform: scale(0.93);
      opacity: 0;
      backface-visibility: hidden;
      animation: pulsed 1.5s linear infinite;
    }

    &:after {
      animation-delay: 0.5s;
    }
  }

  .bullets-container {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
    transform: scale(0.84);

    span {
      position: absolute;
      transition: all 0.5s;
      border: 1px solid ${colors.green};
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin: 7px;
      background: ${colors.white};
      top: 50%;

      left: 50%;
      &.active-bullet {
        width: 40px;
        height: 40px;
        transition: all 0.5s ease-in-out;
        background: ${colors.green};
      }

      &:nth-child(1) {
        transform: translate(-21px, -266px);
        &.active-bullet {
          transform: translate(-29px, -275px);
        }
      }

      &:nth-child(2) {
        transform: translate(-193px, -193px);

        &.active-bullet {
          transform: translate(-202px, -203px);
        }
      }
      &:nth-child(3) {
        transform: translate(-266px, -18px);
        &.active-bullet {
          transform: translate(-274px, -28px);
        }
      }
      &:nth-child(4) {
        transform: translate(-191px, 161px);
        &.active-bullet {
          transform: translate(-199px, 154px);
        }
      }
      &:nth-child(5) {
        transform: translate(-15px, 231px);
        &.active-bullet {
          transform: translate(-24px, 221px);
        }
      }
      &:nth-child(6) {
        transform: translate(158px, 159px);
        &.active-bullet {
          transform: translate(148px, 152px);
        }
      }
      &:nth-child(7) {
        transform: translate(231px, -19px);
        &.active-bullet {
          transform: translate(222px, -25px);
        }
      }

      &:nth-child(8) {
        transform: translate(156px, -195px);
        &.active-bullet {
          transform: translate(151px, -203px);
        }
      }
    }
  }

  @media (max-width: 425px) {
    top: 46%;
    left: 50%;
    transform: translate(-50%, -56%) scale(0.46)
      ${(props) => props.rotateMob ?? ''};
  }
`;
