import { v4 as uuidv4 } from 'uuid';

const items = [
  {
    id: uuidv4(),
    title: 'Design & <br />Inovação_',
    titleMob: 'Design & Inovação_',
    subTitle: `Criatividade aplicada!`,
    paragraph: `Nosso processo criativo inclui métricas de design que centralizam o cliente e conecta o negócio :) Isso nos capacita estabelecer uma cultura inovadora, ter visibilidade de futuro e evoluir em novos produtos! Nossos Designers estão preparados para co-criar!`,
  },
  {
    id: uuidv4(),
    title: 'Desen- <br />volvimento',
    titleMob: 'Desenvolvimento',
    subTitle: `Dando vida ao seu projeto!`,
    paragraph: `Nossa área de tecnologia esta pronta para compreender, manusear e aplicar o que há de mais atual na construção de sites, aplica- tivos, softwares, bancos de dados e métricas que consolidam sua estratégia.`,
  },

  {
    id: uuidv4(),
    title: 'Business- <br />Intelligence',
    titleMob: 'Business Inteligence',
    subTitle: `Tratando manualmente seus dados?`,
    paragraph: `Business Intelligence <span>(BI)</span> é o conjunto de estratégias e tecnologias para a coleta, organização, análise e visualização de dados com objetivo de gerar informações para a inteligência de seu negócio. Temos Métricas e Dashboards que irão te levar Para uma nova fase!`,
  },
  {
    id: uuidv4(),
    title: 'Quality- <br />Assurance',
    titleMob: 'Quality Assurance',
    subTitle: `Acompanhamos a evolução!`,
    paragraph: `Quality Assurance <span>(QA)</span> para elevar os padrões de qualidade e garantir maior segurança, credibilidade e a excelência no desen- volvimento de seus projetos. Diminuindo drasticamente o número de refações futuras, provenientes de críticas e sugestões de usuários.`,
  },
  {
    id: uuidv4(),
    title: 'Design & <br />Inovação_',
    titleMob: 'Design & Inovação_',
    subTitle: `Criatividade aplicada!`,
    paragraph: `Nosso processo criativo inclui métricas de design que centralizam o cliente e conecta o negócio :) Isso nos capacita estabelecer uma cultura inovadora, ter visibilidade de futuro e evoluir em novos produtos! Nossos Designers estão preparados para co-criar!`,
  },
  {
    id: uuidv4(),
    title: 'Desen- <br />volvimento',
    titleMob: 'Desenvolvimento',
    subTitle: `Dando vida ao seu projeto!`,
    paragraph: `Nossa área de tecnologia esta pronta para compreender, manusear e aplicar o que há de mais atual na construção de sites, aplica- tivos, softwares, bancos de dados e métricas que consolidam sua estratégia.`,
  },

  {
    id: uuidv4(),
    title: 'Business- <br />Inteligence',
    titleMob: 'Business Inteligence',
    subTitle: `Tratando manualmente seus dados?`,
    paragraph: `Business Intelligence <span>(BI)</span> é o conjunto de estratégias e tecnologias para a coleta, organização, análise e visualização de dados com objetivo de gerar informações para a inteligência de seu negócio. Temos Métricas e Dashboards que irão te levar Para uma nova fase!`,
  },
  {
    id: uuidv4(),
    title: 'Quality- <br />Assurance',
    titleMob: 'Quality Assurance',
    subTitle: `Acompanhamos a evolução!`,
    paragraph: `Quality Assurance <span>(QA)</span> para elevar os padrões de qualidade e garantir maior segurança, credibilidade e a excelência no desen- volvimento de seus projetos. Diminuindo drasticamente o número de refações futuras, provenientes de críticas e sugestões de usuários.`,
  },
];

export default items;
