//? styles
import * as Styles from './styles';

function TitleSection({ Title, SubTitleBold, SubTitle, BorderColor, Color }) {
  return (
    <>
      <Styles.Container BorderColor={BorderColor} Color={Color}>
        {/* <h2>{Title}</h2> */}
        <h2 className="title">{Title}</h2>
        <h3 className="subtitle">
          <span>{SubTitleBold}</span> <br />
          {SubTitle}
        </h3>
      </Styles.Container>
    </>
  );
}

export default TitleSection;
