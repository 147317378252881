import { v4 as uuidv4 } from 'uuid';

export const items = [
  {
    id: uuidv4(),
    title: 'Mercado Livre',
    year: '2019',
    client: 'cliente <span>MERCADO LIVRE</span>',
    project: 'projeto<span>Tecnologia & Inovação</span>',
  },
  {
    id: uuidv4(),
    title: 'BIA - Bradesco Seguros',
    year: '2020',
    client: 'cliente <span>BRADESCO</span>',
    project: 'projeto <span>Tecnologia & Inovação</span>',
  },
  {
    id: uuidv4(),
    title: 'Bravogrc',
    year: '2020',
    client: 'cliente <span>BRAVOGRC</span>',
    project: 'projeto <span>App Mobile / UX-UI-UW e Dev</span>',
  },
  {
    id: uuidv4(),
    title: 'Trutec',
    year: '2021',
    client: 'cliente <span>TRUTEC</span>',
    project: 'projeto <span>App Mobile / UX-UI-UW e Dev</span>',
  },
  {
    id: uuidv4(),
    title: 'Bricktech',
    year: '2022',
    client: 'cliente <span>BRICKTECH</span>',
    project: 'projeto <span>App Mobile / UX-UI-UW e Dev</span>',
  },
  {
    id: uuidv4(),
    title: 'Odontoprev',
    year: '2022',
    client: 'cliente <span>ODONTOPREV</span>',
    project: 'projeto <span>App Mobile / UX-UI-UW</span>',
  },
];

export default items;
