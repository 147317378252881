import Logo01 from '../../../images/logos-client/logo-01.png';
import Logo02 from '../../../images/logos-client/logo-02.png';
import Logo03 from '../../../images/logos-client/logo-03.png';
import Logo04 from '../../../images/logos-client/logo-04.png';
import Logo05 from '../../../images/logos-client/logo-05.png';
import Logo06 from '../../../images/logos-client/logo-06.png';
import Logo07 from '../../../images/logos-client/logo-07.png';
import Logo08 from '../../../images/logos-client/logo-08.png';
import Logo09 from '../../../images/logos-client/logo-09.png';
import Logo10 from '../../../images/logos-client/logo-10.png';
import Logo11 from '../../../images/logos-client/logo-11.png';
import Logo12 from '../../../images/logos-client/logo-12.png';
import Logo13 from '../../../images/logos-client/logo-13.png';
import Logo14 from '../../../images/logos-client/logo-14.png';
import Logo15 from '../../../images/logos-client/logo-15.png';
import Logo16 from '../../../images/logos-client/logo-16.png';
import Logo17 from '../../../images/logos-client/logo-17.png';
import Logo18 from '../../../images/logos-client/logo-18.png';
import Logo19 from '../../../images/logos-client/logo-19.png';
import Logo20 from '../../../images/logos-client/logo-20.png';
import Logo21 from '../../../images/logos-client/logo-21.png';
import Logo22 from '../../../images/logos-client/logo-22.png';
import Logo23 from '../../../images/logos-client/logo-23.png';

export const items = [
  { name: 'bradesco', icon: Logo01 },
  { name: 'redbull', icon: Logo02 },
  { name: 'odontoprev', icon: Logo03 },
  { name: 'bradesco seguros', icon: Logo04 },
  { name: 'azul seguros', icon: Logo05 },
  { name: 'next', icon: Logo06 },
  { name: 'nestle', icon: Logo07 },
  { name: 'mercado livre', icon: Logo08 },
  { name: 'bradesco saúde', icon: Logo09 },
  { name: 'CNseg', icon: Logo10 },
  { name: 'trutec', icon: Logo11 },
  { name: 'bradesco mediservice', icon: Logo12 },
  { name: 'bp', icon: Logo13 },
  { name: 'aqui card', icon: Logo14 },
  { name: 'bravogrc', icon: Logo15 },
  { name: 'dental parte', icon: Logo16 },
  { name: 'tempo', icon: Logo17 },
  { name: 'real grandeza', icon: Logo18 },
  { name: 'guarupass', icon: Logo19 },
  { name: 'idemia', icon: Logo20 },
  { name: 'dantas e leo', icon: Logo21 },
  { name: 'connect move', icon: Logo22 },
  { name: 'rdl music', icon: Logo23 },
  { name: '', icon: '' },
  { name: '', icon: '' },
  { name: '', icon: '' },
  { name: '', icon: '' },
  { name: '', icon: '' },
  { name: '', icon: '' },
];
