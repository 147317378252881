import styled from 'styled-components';
import colors from 'styles/colors';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  position: relative;
  background: ${colors.whiteLight};

  .button-scroll {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    color: ${colors.green};
    border: none;
    outline: none;
    background: transparent;
    position: absolute;
    bottom: 80px;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 13px;
    height: 70px;
    user-select: none;
    span {
      width: 1px;
      height: 40px;
      background: ${colors.green};
      margin-bottom: 15px;
      display: block;
      animation: scroll-down 1.8s ease-in-out infinite;
      transform: rotate(180deg);
    }
  }

  @media (max-width: 1450px) {
    .button-scroll {
      bottom: 20px;
    }
  }
  @keyframes scroll-down {
    0% {
      height: 0px;
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      height: 40px;
      opacity: 0;
    }
  }
`;

export const Content = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  border-radius: 30px 30px 0 0;
  background: ${colors.gray};
  position: relative;

  .text-content {
    position: relative;
    margin: 0px 0 65px 0;
    .title {
      font-size: 55px;
      font-weight: 800;
      font-style: italic;
      color: ${colors.white};
    }
    .icon {
      position: absolute;
      top: 35px;
      left: -53px;
      img {
        transform: scale(0.6);
      }
    }
  }

  .logo-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: calc(min(1100px, 100%) - 20px);
    img {
      height: 30px;
      margin: 30px 25px;
      &:nth-child(13) {
        height: 50px;
      }
      &:nth-child(14) {
        height: 25px;
      }
      &:nth-child(21) {
        height: 25px;
      }
      &:nth-child(22) {
        align-self: flex-start;
      }
    }
  }

  @media (max-width: 1450px) {
    .text-content {
      position: relative;
      margin: 30px 0 65px 0;

      .title {
        font-size: 45px;
        font-weight: 800;
        font-style: italic;
        color: ${colors.white};
      }
      .icon {
        position: absolute;
        top: 23px;
        left: -50px;
        img {
          transform: scale(0.6);
        }
      }
    }

    .logo-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      width: calc(min(1000px, 100%) - 20px);
      img {
        height: 25px;
        margin: 20px 25px;
        &:nth-child(13) {
          height: 50px;
        }
        &:nth-child(14) {
          height: 25px;
        }
        &:nth-child(21) {
          height: 25px;
        }
        &:nth-child(22) {
          align-self: flex-start;
        }
      }
    }
  }

  @media (max-width: 425px) {
    .text-content {
      position: relative;
      margin: 0px 0 90px;
      .title {
        font-size: 40px;
        line-height: 25px;
      }
      .icon {
        position: absolute;
        transform: unset;
        top: 10px;
        left: -50px;
        img {
          transform: scale(0.6);
        }
      }
    }

    .logo-content {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: wrap;
      width: 80vw;
      img {
        height: 20px;
        margin: 10px;
        &:nth-child(13) {
          height: 25px;
        }
        &:nth-child(14) {
          height: 20px;
        }
        &:nth-child(21) {
          height: 20px;
        }
        &:nth-child(23) {
          height: 30px;
        }
      }
    }
  }
`;

export const ElipseContainer = styled.div`
  position: absolute;
  width: 180px;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -90px;
  z-index: 99;

  .elipse {
    width: 100%;
    height: 100%;
    border: 4px dotted ${colors.white};
    border-radius: 50%;
    animation: roundElipse 10s linear both infinite;
  }

  .icon {
    position: absolute;
  }

  @media (max-width: 1450px) {
    width: 150px;
    height: 150px;
    top: -75px;
  }
  @keyframes roundElipse {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
