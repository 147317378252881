import styled from 'styled-components';
import { rules } from 'styles/breakpoints';
import colors from 'styles/colors';

export const Container = styled.section`
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  transition: 0.3s;
  position: relative;
  background: ${colors.gray};

  .video-bg {
    width: 1920px !important;
    height: auto !important;
  }

  video {
    position: absolute;
    min-width: 100%;
    height: 100vh;
    z-index: 1;
    margin: 0 auto;
    opacity: 0.3;
  }

  .container-box {
    width: ${rules.xl};
    display: flex;
    align-items: center;
    transition: 0.3s;
    margin: 0 auto;
    z-index: 9;
    .title {
      color: ${colors.white};
      font-weight: normal;
      font-size: 115px;
      line-height: 120px;
      font-weight: 300;

      .works {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        height: 110px;
        font-weight: 900;

        b {
          color: ${colors.green};
          height: 100%;

          animation: spin_works 9.6s ease-in-out 0s infinite both;
        }
      }
    }

    .button-scroll {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      text-decoration: none;
      color: ${colors.green};
      border: none;
      outline: none;
      background: transparent;
      position: absolute;
      bottom: 80px;
      left: 50%;
      transform: translate(-50%, 0);
      font-size: 13px;
      height: 70px;
      user-select: none;
      span {
        width: 1px;
        height: 40px;
        background: ${colors.green};
        margin-bottom: 15px;
        display: block;
        animation: scroll-down 1.8s ease-in-out infinite;
        transform: rotate(180deg);
      }
    }
  }

  @media (max-width: 425px) {
    .container-box {
      .title {
        font-size: 40px;
        line-height: 55px;

        .works {
          height: 60px;
        }
      }
    }
  }

  @keyframes scroll-down {
    0% {
      height: 0px;
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      height: 40px;
      opacity: 0;
    }
  }
  @keyframes spin_works {
    20% {
      transform: translateY(-100%);
    }

    40% {
      transform: translateY(-200%);
    }

    60% {
      transform: translateY(-300%);
    }
    80% {
      transform: translateY(-400%);
    }
    100% {
      transform: translateY(-500%);
    }
  }
`;
