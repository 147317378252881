import styled from 'styled-components';
import { rules } from 'styles/breakpoints';
import colors from 'styles/colors';
import { motion } from 'framer-motion';

//? images
import sectionBg08 from '../../../images/bgs/section-bg-08.png';

export const Container = styled(motion.div)`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background: url(${sectionBg08}) no-repeat center;
  background-size: cover;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
`;

export const Content = styled.div`
  width: ${rules.xl};
  height: 466px;
  display: flex;
  justify-content: space-between;

  .country-box {
    img {
      margin-right: 10px;
    }
  }

  @media (max-width: 425px) {
    flex-direction: column-reverse;
  }
`;
export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 425px) {
    justify-content: center;
    align-items: center;
    margin-bottom: -50px;
    .logo-box {
      img {
        transform: scale(0.7);
      }
    }

    .country-box {
      img {
        transform: scale(0.6);
        margin: 0;
      }
    }
  }
`;
export const RightSide = styled.div`
  .contact,
  .phone,
  .address,
  .social-media,
  .copyright {
    margin-bottom: 40px;
    .title {
      font-size: 18px;
      font-style: italic;
      font-weight: 800;
      margin: 0;
    }
    .paragraph {
      font-size: 24px;
    }
    .contact-link,
    .iso-link {
      font-size: 24px;
      margin: 0;
      color: #000;
      transition: 0.3s;
      &:hover {
        color: ${colors.greenDark};
      }
      svg {
        margin-right: 30px;
      }
    }
  }

  .copyright {
    h4 {
      font-size: 18px;
      font-weight: 400;
    }
  }

  @media (max-width: 425px) {
    flex-direction: column-reverse;

    .contact,
    .phone,
    .address,
    .social-media,
    .copyright {
      margin: 0 0 20px;
      .title {
        font-size: 18px;
        font-style: italic;
        font-weight: 800;
      }
      .paragraph {
        font-size: 20px;
      }
      .contact-link,
      .iso-link {
        font-size: 14px;
        margin: 0;
        color: #000;
        transition: 0.3s;
        &:hover {
          color: ${colors.greenDark};
        }
        svg {
          margin-right: 30px;
        }
      }
    }

    .copyright {
      h4 {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
`;
