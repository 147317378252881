import React from 'react';
import { motion } from 'framer-motion';

//? styles
import * as Styles from './styles';

//? images
import contact from '../../../images/contact.png';
import contactWhatsApp from '../../../images/contact-whatsapp.png';

//? helpers
import { cardVariants } from '../../../helpers/animation';

function SeventhComponent() {
  return (
    <Styles.Container initial="initial" animate="animate" id="boxscroll7">
      <Styles.Content
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.8 }}
      >
        <motion.div className="text-content">
          <motion.h2 variants={cardVariants} className="title">
            Contato
          </motion.h2>

          <motion.div variants={cardVariants} className="icon">
            <img src={contact} alt="" />
          </motion.div>

          <motion.h3 variants={cardVariants} className="subtitle">
            Vamos conversar sobre seu desafio?
          </motion.h3>

          <motion.p className="paragraph" variants={cardVariants}>
            Adoraríamos tomar um café para falarmos sobre isso:) Nos chame no{' '}
            <br />
            <motion.span variants={cardVariants}>whatsApp!</motion.span>
          </motion.p>
        </motion.div>

        <motion.div className="contact-content">
          <motion.div className="contact-img">
            <img src={contactWhatsApp} alt="Contato WhatsApp" />
          </motion.div>
          <motion.div className="contact-info">
            <a
              href="https://api.whatsapp.com/send?phone=5511940694292"
              title="fale conosco"
              target="_blank"
              rel="noreferrer"
              className="contact-link"
            >
              (11) 94069-4292
            </a>
            <a href="mailto:comercial@sabion.com.br" className="contact-link">
              comercial@sabion.com.br
            </a>
          </motion.div>
        </motion.div>
      </Styles.Content>
    </Styles.Container>
  );
}

export default SeventhComponent;
