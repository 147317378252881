import React from 'react';
import { motion } from 'framer-motion';

//? styles
import * as Styles from './styles';

//? images
import thunder from '../../../images/thunder.png';
import client from '../../../images/client.png';

//? data
import { items } from '../../../data/pages/06-SixthComponent';

//? helpers
import {
  cardVariants,
  cardVariantsLeft,
  cardVariantsRight,
} from '../../../helpers/animation';

function SixthComponent() {
  return (
    <Styles.Container initial="initial" animate="animate" id="boxscroll6">
      <Styles.ElipseContainer>
        <div className="elipse"></div>
        <div className="icon">
          <img src={thunder} width={35} alt="" />
        </div>
      </Styles.ElipseContainer>
      <Styles.Content
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.8 }}
      >
        <motion.div className="text-content">
          <motion.h2 variants={cardVariantsLeft} className="title">
            Clientes
          </motion.h2>
          <motion.div variants={cardVariantsRight} className="icon">
            <img src={client} alt="" />
          </motion.div>
        </motion.div>
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.8 }}
          className="logo-content"
        >
          {items.map((item, index) => {
            const { name, icon } = item;
            return (
              <motion.img
                key={index}
                src={icon}
                alt={name}
                variants={cardVariants}
              />
            );
          })}
        </motion.div>
      </Styles.Content>
      <div className="button-scroll">
        <span></span>
        Scroll Down
      </div>
    </Styles.Container>
  );
}

export default SixthComponent;
