import React from 'react';
import { Button } from './styles';

// images

import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

function BtnSliders({ direction, moveSlide }) {
  return (
    <>
      <Button
        onClick={moveSlide}
        className={
          direction === 'prev' ? 'button-slide prev' : 'button-slide next'
        }
        id={
          direction === 'prev' ? 'seta_ant_boxscroll3' : 'seta_prox_boxscroll3'
        }
      >
        {direction === 'prev' ? <FaChevronLeft /> : <FaChevronRight />}
      </Button>
    </>
  );
}

export default BtnSliders;
