import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
* {
    padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body, #root {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 400 ;
}

*, button, input, a {
  color: inherit;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
    font-family: 400 ;
}



code {
  font-family: 'Montserrat', sans-serif;
    font-family: 400 ;
}

video{
  width: unset !important;
    height: unset !important;
}


`;
