import styled from 'styled-components';

import colors from 'styles/colors';

export const Button = styled.span`
  &.button-slide {
    position: absolute;
    color: ${colors.green};
    background: ${colors.white};
    box-shadow: 0px 0px 5px #00000030;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 28px;

    transition: all 0.2s;
    &:hover {
      transform: scale(1.15);
    }

    &.prev {
      left: 35px;
    }
    &.next {
      right: 35px;
    }
  }

  @media (max-width: 425px) {
    &.button-slide {
      width: 40px;
      height: 40px;

      svg {
        font-size: 20px;
      }
      &:hover {
        transform: scale(1);
      }

      &.prev {
        left: -89px;
      }
      &.next {
        left: 34px;
      }
    }
  }
`;
