import React, { useEffect, useState } from 'react';
import ReactPageScroller from 'react-page-scroller';
import { AnimatePresence } from 'framer-motion';
import { Pager } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import Cookies from 'js-cookie'

import Logo from '../src/images/logo.png';
import cookie from '../src/images/cookie.png';

import FirstComponent from 'components/Section/01-FirstComponent';
import SecondComponent from 'components/Section/02-SecondComponent';
import ThirdComponent from 'components/Section/03-ThirdComponent';
import FourthComponent from 'components/Section/04-FourthComponent';
import FifthComponent from 'components/Section/05-FifthComponent';
import SixthComponent from 'components/Section/06-SixthComponent';
import SeventhComponent from 'components/Section/07-SeventhComponent';
import EighthComponent from 'components/Section/08-EighthComponent';
import SocialMedia from 'components/SocialMedia';

import {
  HeaderContainer,
  BrandContent,
  IconsContent,
  Container,
  FixedBar,
} from './styles';

import { FaChevronUp } from 'react-icons/fa';

const COOKIE_LGPD_POLICY = '_lgpd-policy';

export default function App() {
  const [currentPage, setCurrentPage] = useState(null);
  const [policyCookie, setPolicyCookie] = useState(false);

  console.log(Cookies.get(COOKIE_LGPD_POLICY))

  useEffect(() => {
    const hasPolicyCookie = Cookies.get(COOKIE_LGPD_POLICY);

    if (hasPolicyCookie) {
      setPolicyCookie(hasPolicyCookie)
    }

    console.log(document.cookies);
  }, [])

  const handleOnClick = () => {
    Cookies.set(COOKIE_LGPD_POLICY, 'accepted');
    setPolicyCookie('accepted');
  }
  
// export default class App extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { currentPage: null, policy: false };
//   }

  const handlePageChange = (number) => {
    // this.setState({ currentPage: number });
    setCurrentPage(number);
  };

  const handleBeforePageChange = (number) => {};

  return (
    <AnimatePresence exitBeforeEnter>
      <React.Fragment>
        <HeaderContainer>
          <header className="header">
            <BrandContent>
              <Pager>
                <Pager.Item
                  key={1}
                  eventKey={1 - 1}
                  onSelect={handlePageChange}
                  id="logo_sabion"
                >
                  <img
                    src={Logo}
                    width={100}
                    alt="Sabion | Creative Agency"
                  />
                </Pager.Item>
              </Pager>
            </BrandContent>

            <IconsContent>
              <SocialMedia
                instagramTitle="logo_instagram_top"
                linkedinTitle="logo_linkedin_top"
              />
            </IconsContent>
          </header>
        </HeaderContainer>

        <ReactPageScroller
          pageOnChange={handlePageChange}
          onBeforePageScroll={handleBeforePageChange}
          customPageNumber={currentPage}
        >
          <FirstComponent />
          <SecondComponent />
          <ThirdComponent />
          <FourthComponent />
          <FifthComponent />
          <SixthComponent />
          <SeventhComponent />
          <EighthComponent />
        </ReactPageScroller>

        <Container>
          <Pager className="back-top-top" bsSize="large">
            <Pager.Item
              key={1}
              eventKey={1 - 1}
              onSelect={handlePageChange}
              id="seta_topo"
            >
              <FaChevronUp />
            </Pager.Item>
          </Pager>
        </Container>


        {!policyCookie && (
          <FixedBar>
            <div>
              <span className='cookie'><img src={cookie} width="64" alt="" /></span>
              <h3>Controle sua privacidade</h3>
              <a className="fixed-dismiss" onClick={handleOnClick}><FaTimes size={20}/></a>
              <p>
                Usamos cookies para personalizar o conteúdo, adaptar e medir anúncios, além de oferecer uma experiência mais segura a você. <br/>
                Ao continuar a navegação em nosso site, você concorda com o uso dessas informações. Leia nossa <a href="/files/politica-de-privacidade.pdf" target="_blank">Política de Privacidade</a> e saiba mais.
              </p>
            </div>
          </FixedBar>
        )}
      </React.Fragment>
    </AnimatePresence>
  );
}
