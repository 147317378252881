import React, { useState, useEffect } from 'react';
//? styles
import * as Styles from './styles';

//? components
import TitleSection from 'components/TitleSection';

//? icons
import { FaChevronUp, FaChevronDown, FaCaretRight } from 'react-icons/fa';

//? data
import items from '../../../data/pages/04-FourthComponent';

function FourthComponent() {
  const [dataInfo] = useState(items);
  const [slideIndex, setSlideIndex] = useState(0);
  const [activeArrowMove] = useState(71.3);
  const [activeArrowMoveMob] = useState(54.5);

  useEffect(() => {
    const lastIndex = dataInfo.length - 1;
    if (slideIndex < 0) {
      setSlideIndex(lastIndex);
    }
    if (slideIndex > lastIndex) {
      setSlideIndex(0);
    }
  }, [slideIndex, dataInfo]);

  // useEffect(() => {
  //   let slider = setInterval(() => {
  //     setSlideIndex(slideIndex + 1);
  //   }, 4000);
  //   return () => {
  //     clearInterval(slider);
  //   };
  // }, [slideIndex]);
  
  return (
    <>
      <Styles.Container id="boxscroll4">
        <TitleSection SubTitleBold={'CASES'} BorderColor={true} Color={true} />
        <section className="section">
          <div className="section-center">
            {dataInfo.map((item, indexSlider) => {
              const { id, title, year, client, project } = item;
              let position = 'nextSlide';
              if (indexSlider === slideIndex) {
                position = 'activeSlide';
              }
              if (
                indexSlider === slideIndex - 1 ||
                (slideIndex === 0 && indexSlider === dataInfo.length - 1)
              ) {
                position = 'lastSlide';
              }
              return (
                <article className={position} key={id}>
                  <div className="title-content">
                    <p className="title">
                      <span dangerouslySetInnerHTML={{ __html: title }} />
                    </p>
                    <p className="text">
                      <span dangerouslySetInnerHTML={{ __html: year }} />
                    </p>
                  </div>
                  <div className="img-content">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        `/images/timeline/cases-0${indexSlider + 1}.png`
                      }
                      alt=""
                    />
                  </div>
                  <div className="figcaption-content">
                    <p className="title">
                      <span dangerouslySetInnerHTML={{ __html: client }} />
                    </p>
                    <p className="text">
                      <span dangerouslySetInnerHTML={{ __html: project }} />
                    </p>
                  </div>
                </article>
              );
            })}
          </div>
          <div className="box-button">
            <div
              className="prev"
              id="seta_ant_boxscroll4"
              onClick={() => setSlideIndex(slideIndex - 1)}
            >
              <FaChevronUp />
            </div>

            <div
              className="next"
              id="seta_prox_boxscroll4"
              onClick={() => setSlideIndex(slideIndex + 1)}
            >
              <FaChevronDown />
            </div>

            {items.map((item, index) => (
              <span
                key={index}
                className={slideIndex === index ? 'active-bullet-timeline' : ''}
              ></span>
            ))}

            <Styles.TimelineArrow
              moveArrow={`translate(-630px,  ${
                activeArrowMove * slideIndex
              }px)`}
              moveArrowMob={`translate(-130px,  ${
                activeArrowMoveMob * slideIndex
              }px)`}
            >
              <FaCaretRight />
            </Styles.TimelineArrow>
          </div>
        </section>
      </Styles.Container>
    </>
  );
}

export default FourthComponent;
