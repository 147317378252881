import styled from 'styled-components';
import colors from 'styles/colors';
import { motion } from 'framer-motion';

import sectionBg05 from '../../../images/bgs/section-bg-05.png';

export const Container = styled(motion.div)`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background: url(${sectionBg05}) no-repeat center;
  background-size: cover;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
`;

export const Content = styled(motion.div)`
  width: 950px;
  height: calc(min(641px, 100%) - 100px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .text-container {
    .title {
      font-size: 30px;
      letter-spacing: 4px;
      font-weight: 700;
      color: ${colors.pink};
      margin-bottom: 20px;
    }
    .subtitle {
      font-size: 65px;
      font-style: italic;
      line-height: 75px;
      font-weight: 800;
    }
  }

  .img-container {
    display: flex;
    justify-content: space-between;
    .label-img {
      width: 200px;
    }
  }
  @media (max-width: 1450px) {
    width: 800px;
    height: calc(min(641px, 100%) - 200px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .text-container {
      .title {
        font-size: 20px;
        letter-spacing: 4px;
        font-weight: 700;
        color: ${colors.pink};
        margin-bottom: 10px;
      }
      .subtitle {
        font-size: 55px;
        font-style: italic;
        line-height: 60px;
        font-weight: 800;
      }
    }

    .img-container {
      display: flex;
      justify-content: space-between;
      .label-img {
        width: 180px;
      }
    }
  }

  @media (max-width: 425px) {
    width: 80vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .text-container {
      margin: 8px 0 -50px 0;
      .title {
        font-size: 20px;

        margin: 0 0 20px;
        br {
          display: none;
        }
      }
      .subtitle {
        font-size: 30px;
        line-height: 35px;
      }
    }

    .img-container {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      .label-img {
        width: 110px;
        margin: 10px 20px;
      }
    }
  }
`;

export const ElipseContainer = styled.div`
  position: absolute;
  width: 180px;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: -90px;

  .elipse {
    width: 100%;
    height: 100%;
    border: 4px dotted ${colors.gray};
    border-radius: 50%;
    animation: roundElipse 10s linear both infinite;
  }

  .icon {
    position: absolute;
  }

  @media (max-width: 1450px) {
    width: 150px;
    height: 150px;
    bottom: -75px;
  }
  @keyframes roundElipse {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
