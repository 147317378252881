import React from 'react';

//? styles
import * as Styles from './styles';

//? images
import logo from '../../../images/logo-black.png';
import flagBrazil from '../../../images/svg/flag-01.svg';
import flagPortugal from '../../../images/svg/flag-02.svg';

import { FaInstagram, FaLinkedinIn } from 'react-icons/fa';

function EighthComponent() {
  return (
    <Styles.Container id="boxscroll8">
      <Styles.Content>
        <Styles.LeftSide>
          <div className="logo-box">
            <img
              src={logo}
              alt="sabion | Creativity,Technology and Innovation"
            />
          </div>
          <div className="country-box">
            <img
              src={flagBrazil}
              alt="sabion | Creativity,Technology and Innovation"
            />{' '}
            <img
              src={flagPortugal}
              alt="sabion | Creativity,Technology and Innovation"
            />
          </div>
        </Styles.LeftSide>
        <Styles.RightSide>
          <div className="contact">
            <h3 className="title">Fale conosco</h3>
            <a href="mailto:comercial@sabion.com.br" className="contact-link">
              comercial@sabion.com.br
            </a>
          </div>
          <div className="phone">
            <h3 className="title">Telefone</h3>
            <a
              href="https://api.whatsapp.com/send?phone=5511940694292"
              title="fale conosco"
              target="_blank"
              rel="noreferrer"
              className="contact-link"
            >
              (11) 94069-4292
            </a>
          </div>
          <div className="phone">
            <h3 className="title">Nossa Identidade</h3>
            <a
              href="https://iso-9001.sabion.com.br/home"
              title="Conheça nossa Missão, Visão e Valores."
              target="_blank"
              rel="noreferrer"
              className="iso-link"
              id="iso_link_footer"
            >
              Portal ISO 9001:2015
            </a>
          </div>
          <div className="address">
            <h3 className="title">Onde estamos?</h3>
            <a
              href="https://g.page/shopping-alpha-square-mall?share"
              target="_blank"
              alt="Nosso Endereço"
              rel="noreferrer"
              className="contact-link"
            >
              Av. Sagitário, 138 – Sls. 601 – 618 <br />
              Alpha Square Mall Torre City Alphaville <br />
              Barueri – SP
            </a>
            <p className="paragraph"></p>
          </div>
          <div className="social-media">
            <a
              href="https://www.instagram.com/sabion_digital/"
              target="_blank"
              alt="Visite nosso Instagram"
              rel="noreferrer"
              className="contact-link"
              id="logo_instagram_footer"
            >
              <FaInstagram size={22} />
            </a>

            <a
              href="https://www.linkedin.com/company/sabion-digital"
              alt="Acesse o nosso LinkedIn"
              target="_blank"
              rel="noreferrer"
              className="contact-link"
              id="logo_linkedin_footer"
            >
              <FaLinkedinIn size={22} />
            </a>
          </div>
          <div className="copyright">
            <h4>© 2022 All Rights Reserved</h4>
          </div>
        </Styles.RightSide>
      </Styles.Content>
    </Styles.Container>
  );
}

export default EighthComponent;
