import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.section`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-image: radial-gradient(
      at 90% 85%,
      ${colors.green} 0,
      transparent 54%
    ),
    radial-gradient(at 64% 66%, ${colors.greenDark} 0, transparent 59%),
    radial-gradient(at 48% 35%, ${colors.green} 0, transparent 56%),
    radial-gradient(at 43% 53%, ${colors.greenDark} 0, transparent 43%),
    radial-gradient(at 78% 13%, ${colors.green} 0, transparent 55%),
    radial-gradient(at 66% 95%, ${colors.greenDark} 0, transparent 55%),
    radial-gradient(at 37% 9%, ${colors.green} 0, transparent 56%);
  background-size: 300% 300%;
  animation: bg-move 2s ease-in-out infinite alternate;
  .section {
    width: min(1000px, 90vw);
    position: relative;
    margin: 55px 0 0 150px;
    .section-center {
      margin: 0 auto;

      width: min(1000px, 80vw);
      height: 708px;
      text-align: center;
      position: relative;
      display: flex;
      overflow: hidden;

      article {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all 1s ease-in-out;

        &.activeSlide {
          opacity: 1;
          transform: translateY(0);
        }
        &.lastSlide {
          transform: translateY(-100%);
        }
        &.nextSlide {
          transform: translateY(100%);
        }

        .title-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0px 0 10px;
          .title,
          .text {
            margin: 0px;
            font-size: 20px;
            font-weight: 300;
            span {
              font-family: 'Raleway', sans-serif;
            }
          }
        }

        .img-content {
          display: flex;
          justify-content: center;
          width: 100%;
          img {
            width: 100%;
          }
        }

        .figcaption-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 10px 0 0px;
          .title,
          .text {
            margin: 0px;
            font-size: 20px;
            font-weight: 300;
            span {
              font-family: 'Raleway', sans-serif;

              span {
                &:before {
                  content: '<';
                }
                &:after {
                  content: '>';
                }
              }
            }
          }
        }
      }
    }

    .box-button {
      &:before {
        position: absolute;
        content: '';
        width: 10px;
        height: 360px;
        top: 50%;
        left: -89px;
        transform: translate(-5%, -50%);
        background: linear-gradient(
          ${colors.white} 10%,
          rgba(255, 255, 255, 0) 0%
        );
        background-size: 10px 18px;
        background-repeat: repeat-y;
        z-index: 1px;
      }
      .prev,
      .next {
        position: absolute;
        color: ${colors.gray};
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        cursor: pointer;
        transition: all 0.3s linear;
        left: -100px;
        &:hover {
        }
      }

      .next {
        bottom: 137px;
      }
      .prev {
        top: 100px;
      }

      span {
        position: relative;
        transition: all 0.5s;
        width: 15px;
        height: 5px;
        border-radius: 5px;
        margin: 0 0 0 -92px;
        background: ${colors.white};

        &.active-bullet-timeline {
          transition: all 0.5s;
          background: ${colors.gray};
        }

        &:nth-child(3) {
          position: absolute;
          top: 155px;
        }
        &:nth-child(4) {
          position: absolute;
          top: 226px;
        }

        &:nth-child(5) {
          position: absolute;
          top: 298px;
        }

        &:nth-child(6) {
          position: absolute;
          top: 370px;
        }

        &:nth-child(7) {
          position: absolute;
          top: 441px;
        }
        &:nth-child(8) {
          position: absolute;
          top: 513px;
        }
      }
    }
  }

  @media (max-width: 1450px) {
    .section {
      width: min(700px, 90vw);

      .section-center {
        margin: 0 auto;

        width: min(700px, 80vw);
        height: 513px;

        article {
          .img-content {
            width: 100%;
            img {
              width: 700px;
              height: auto;
            }
          }
        }
      }

      .box-button {
        .prev {
          top: 0px;
        }
        .next {
          bottom: 0;
        }

        span {
          position: relative;
          transition: all 0.5s;
          width: 15px;
          height: 5px;
          border-radius: 5px;
          margin: 0 0 0 -92px;
          background: ${colors.white};

          &.active-bullet-timeline {
            transition: all 0.5s;
            background: ${colors.gray};
          }

          &:nth-child(3) {
            position: absolute;
            top: 76px;
          }
          &:nth-child(4) {
            position: absolute;
            top: 148px;
          }

          &:nth-child(5) {
            position: absolute;
            top: 218px;
          }

          &:nth-child(6) {
            position: absolute;
            top: 290px;
          }

          &:nth-child(7) {
            position: absolute;
            top: 364px;
          }
          &:nth-child(8) {
            position: absolute;
            top: 435px;
          }
        }
      }
    }
  }

  @media (max-width: 425px) {
    .section {
      width: 90vw;
      margin: 0;
      .section-center {
        margin: 0 auto;
        width: 100%;
        height: 350px;

        article {
          .title-content {
            .title,
            .text {
              font-size: 18px;
              font-weight: 300;
            }
          }
          .figcaption-content {
            .title,
            .text {
              width: 45%;
              font-size: 16px;
              font-weight: 300;
            }
          }
          .img-content {
            img {
              width: 100%;
            }
          }
        }
      }
      .box-button {
        position: absolute;
        bottom: 100px;
        left: 50%;
        transform: rotate(-90deg) translate(-30px, 14px);
        &:before {
          left: -85px;
          height: 272px;
          transform: translate(-5px, -56%);
        }
        .prev {
          top: -90px;
          left: -204px;
        }
        .next {
          top: 50px;
          left: -204px;
        }
        span {
          &:nth-child(3) {
            position: absolute;
            top: -154px;
          }
          &:nth-child(4) {
            position: absolute;

            top: -99px;
          }

          &:nth-child(5) {
            position: absolute;
            top: -45px;
          }

          &:nth-child(6) {
            position: absolute;
            top: 10px;
          }

          &:nth-child(7) {
            position: absolute;

            top: 62px;
          }
          &:nth-child(8) {
            position: absolute;

            top: 117px;
          }
        }
      }
    }
  }
  @keyframes bg-move {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 50% 75%;
    }
    100% {
      background-position: 25% 100%;
    }
  }
`;

export const TimelineArrow = styled.div`
  position: absolute;
  height: 20px;
  display: flex;
  flex-direction: column;
  left: 50%;
  top: 149px;
  transition: 0.3s;
  transform: ${(props) => props.moveArrow ?? 'translate(-470px, -50%)'};
  align-items: center;
  svg {
    color: ${colors.gray};
  }

  @media (max-width: 1450px) {
    top: 69px;
    left: 70%;
  }

  @media (max-width: 425px) {
    top: -159px;
    left: -50%;
    transform: ${(props) => props.moveArrowMob ?? 'translate(-470px, -50%)'};
  }
`;
